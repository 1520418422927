.loading-spinner {
    border-top: 4px solid var(--site-dark);
    border-right: 4px solid var(--site-white);
    border-bottom: 4px solid var(--site-dark);
    border-left: 4px solid var(--site-white);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    display: inline-block;
    position: relative;
    left: 1em;
    top: 0.5em;
}

.small {
    border-width: 4px;
    width: 30px;
    height: 30px;
}

.medium {
    border-width: 8px;
    width: 60px;
    height: 60px;
}

.large {
    border-width: 16px;
    width: 120px;
    height: 120px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}