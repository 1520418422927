form.add-to-list-form .submit-section, form.add-to-list-form .field {
    display: inline-block;
    padding-right: 1em;
}

form.add-to-list-form .field {
    width: 30em;
}

form.add-to-list-form input {
    width: 100%;
}

form.add-to-list-form .submit-section button {
    font-size: 1.75em;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    -webkit-border-radius: 100%;;
    border-width: 0;
    box-shadow: 0 1px 2px 2px white;
    background-color: white;
}

form.add-to-list-form .submit-section button span {
    display: block;
    margin-top: -0.15em;
    margin-left: -0.03em;
    text-align: center;
    line-height: 1em;
}