.postlist-main {
    margin-top: 2em;
}

.postlist-main p {
    font-size: 0.75em;
}

.postlist-main button {
    background-color: var(--site-complement1);
    font-size: 1.25em;
    margin-bottom: 0.25em;
}

.postlist-main button:hover {
    background-color: var(--site-complement1-dark);
}

.postlist-main button:disabled, .postlist-main button[disabled] {
    background-color: var(--site-complement1-grey);
}