:root {
    --footer-desktop-height: 30px;
    --site-dark: rgb(35, 8, 59);
    --site-light: rgb(64, 29, 97);
    --site-white: rgb(224, 218, 230);
    --site-complement1: rgb(216, 214, 71);
    --site-complement1-dark: rgb(131, 129, 41);
    --site-complement1-grey: rgb(212, 212, 174);
}

html {
    background: var(--site-light);
    text-align: center;
}

body {
    color: var(--site-white);
    background: transparent;
}

html, body, #root, .page {
    height: 100%;
}

header {
    background: var(--site-dark);
    padding: 1.25em 7.6923%;
}

header h1 {
    display: inline-block;
}

@media (max-width: 1320px) {
    header h1 {
        font-size: 2rem;
    }
}

header p {
    padding-left: 1em;
    font-size: 0.8em;
    display: inline-block;
}

@media (min-width: 1320px) {
    main {
        margin: 0 auto;
        max-width: 1320px;
        position: relative;
        height: 100%;
        position: relative;
    }
}

@media (max-width: 1320px) {
    main {
        padding: 0 5%;
        max-width: 100%;
        position: relative;
        height: 100%;
        position: relative;
    }
}

footer {
    background: var(--site-dark);
    width: 100%;
    text-align: center;
}

/********************** FOOTER ALWAYS ON BOTTOM **********************/
@media (min-width: 1320px) {
    html, body {
        height: 100%;
    }

    main {
        padding-bottom: var(--footer-desktop-height);
    }

    #page {
        min-height: 100%;
        position: relative;
    }

    footer {
        height: var(--footer-desktop-height);
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 1320px) {
    footer {
        display: none;
    }
}