.next-binary-question h3 {
    display: inline-block;
}

.next-binary-question div.separator {
    text-align: center;
    position: relative;
    font-size: 2em;
    margin: 0 auto;
    resize: both;
}

.next-binary-question div.separator div {
    width: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    resize: both;
}

.next-binary-question button {
    background-color: var(--site-complement1);
    font-size: 3em;
    color: var(--site-dark);
    border-radius: 15px;
}

@media (max-width: 780px) {
    .next-binary-question button {
        margin: 0.75em 0;
    }
}

.next-binary-question button:hover {
    background-color: var(--site-complement1-dark);
}